@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// RESET
@import 'incl/reset';
// RESET
@import 'incl/flags';
// ANIMATIONS
@import 'incl/animations';
// FONTS
@import 'incl/fonts';
// MIXINS
@import 'incl/mixins';

@import 'incl/cookieconsent';
@import 'xsmoke/notie';

// Lib
@import 'incl/owl.carousel';
@import 'incl/drift';
@import 'incl/luminous';
@import 'incl/et.icons';

$color-main: #303030 ;
$color-secondary: #b0bec5;
$color-accent: #eea9cf ;

// BUTTONS
.btn {
    display: inline-block;
    border-radius: 30px;
    padding: 14px 40px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    @include transition(all .2s);
    &.btn-accent, &.btn-default {
        background: $color-accent;
        box-shadow: 0px 5px 16px transparentize($color-accent, .6);
        color: #fff;
        border: none;
        &:hover {
            box-shadow: 0px 10px 25px transparentize($color-accent, .6);
        }
        &:focus {
            background: $color-accent;
            box-shadow: 0px 5px 16px transparentize($color-accent, .6);
            color: #fff;
        }
    }
    &.btn-empty {
        background: $color-secondary;
        box-shadow: 0px 5px 16px rgba(0, 0, 0, .08);
        color: #fff;
        &:hover {
            opacity: .8;
            box-shadow: 0px 10px 25px transparentize($color-accent, .6);
        }
    }
}

// GENERAL
html, body {
    font-family: 'HK Grotesk';
    font-size: 16px;
    color: $color-main;
    background: #fbfbfb;
    overflow-x: hidden;
}

a.text-link {
    color: $color-main;
    font-weight: 700;
    @include transition(color .15s);
    &:hover, &:focus, &:active {
        color: $color-accent;
    }
}

ul li.dropdown {
    .dropdown-menu {
        left: -35px;
        top: 40px;
        border: none;
        border-radius: 3px;
        box-shadow: 0px 4px 25px rgba(0,0,0,.07);
        padding: 0;
        min-width: 130px;
        a {
            display: block;
            font-size: 14px;
            color: $color-secondary;
            font-weight: 500;
            padding: 14px 20px;
            text-transform: none;
            @include transition(color .2s, background .2s);
            &:hover {
                background: #f2f2f2;
                color: $color-main;
            }
        }
        &:after {
            content: '';
            position: absolute;
            width:0; height: 0;
            border: 6px solid transparent;
            border-bottom-color: #fff;
            left: calc(50% - 3px);
            top: -12px;
            @include transition(border-color .2s ease);
        }
    }
}

// NAV
nav.nav {
    top:0;
    left: 0;
    width: 100%;
    background:transparent;
    @include transition(background .2s, box-shadow .2s, transform .2s ease-out);
    z-index: 10;
	padding: 20px 0;
	position: fixed;
    &.nav-top {
        .logo {
            width: 100%;
            padding: 15px 0;
            img {
                height: 75px;
                display: block;
                margin: 0 auto;
            }
		}
		&.scrolled {
			background: #fff;
			box-shadow: 0px 10px 20px rgba(176, 190, 197, 0.05); 
		}
		&.product-showing {
			transform: translateY(-400%); 
		}
        .navigation {
            display: block;
            margin: 0 auto;
            text-align: center;
            padding: 0;
            > ul {
                padding: 0;
                margin: 0 20px;
                list-style: none;
                display: inline-block;
                > li {
                    display: inline-block;
                    &.dropdown {
                        > a {
                            display: inline-block;
                            position: relative;
                            &:after {
                                content: '';
                                position: absolute;
                                width:0; height: 0;
                                border: 3px solid transparent;
                                border-top-color: $color-secondary;
                                right: 3px;
                                top: 16px;
                                @include transition(border-color .2s ease);
                            }
                            &:focus {
                                color: $color-main;
                                &:after {
                                    border-top-color: $color-main;
                                }
                            }
                        }
                    }
                    > a {
                        color: $color-secondary;
                        padding: 10px 15px;
                        display: inline-block;
                        text-transform: uppercase;
                        font-size: 14px;
                        font-weight: 700;
                        @include transition(color .2s ease);
                        &.active, &:hover {
                            color: $color-main;
                            &:after {
                                border-top-color: $color-main;
                            }
                        }
                    }
                    &.language-dropdown {
                        a {
                            .flag-icon {
                                margin-right: 10px;
                                width: 14px;
                                position: relative;
                                top: -1px;
                            }
                        }
                    }
                }
            }
            .navigation-right {
                > li {
                    &.dropdown {
                        .dropdown-menu {
                            left: -35px;
                            top: 40px;
                            border: none;
                            border-radius: 3px;
                            box-shadow: 0px 4px 25px rgba(0,0,0,.07);
                            padding: 0;
                            min-width: 130px;
                            a {
                                display: block;
                                font-size: 14px;
                                color: $color-secondary;
                                font-weight: 500;
                                padding: 14px 20px;
                                text-transform: none;
                                @include transition(color .2s, background .2s);
                                &:hover {
                                    background: #f2f2f2;
                                    color: $color-main;
                                }
                            }
                            &:after {
                                content: '';
                                position: absolute;
                                width:0; height: 0;
                                border: 6px solid transparent;
                                border-bottom-color: #fff;
                                left: calc(50% - 3px);
                                top: -12px;
                                @include transition(border-color .2s ease);
                            }
                        }
                    }
                    &.cart-dropdown {
                        > a {
                            position: relative;
                            .cart-quantity {
                                display: inline-block;
                                width: 15px;
                                height: 15px;
                                font-size: 10px;
                                line-height: 15px;
                                text-align: center;
                                color: #fff;
                                background: $color-accent;
                                border-radius: 50%;
                                position: absolute;
                                right: 5px;
                                top: 0px;
                            }
                        }
                        .dropdown-menu {
                            min-width: 350px;
                            margin: 0;
                            left: -140px;
                            li {
                                margin-bottom: 0;
                                > a {
                                    display: block;
                                    width: 100%;
                                    .row > div {
                                        padding: 0 5px;
                                    }
                                }
                                .cart-product-img {
                                    height: 50px;
                                }
                                span {
                                    display: inline-block;
                                    padding: 17px 5px;
                                    &.cart-product-quantity {
                                        font-weight: 600;
                                    }
                                    &.cart-product-name {
                                        white-space: nowrap; 
                                        width: 100%;
                                        overflow: hidden;
                                        text-overflow: ellipsis; 
                                    }
                                }
                                &.subtotal {
                                    padding: 15px 15px;
                                    background: $color-accent;
                                    color: #fff;
                                    a, span {
                                        font-weight: 600;
                                        color: #fff;
                                        padding: 0;
                                    }
                                    a {
                                        text-transform: uppercase;
                                        text-decoration: underline;
                                        &:hover {
                                            background: transparent;
                                        }
                                    }
                                }
                            }
                            ul {
                                list-style: none;
                                padding: 0;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.cart-toggle {
    display: none;
    a {
        color: $color-secondary;
    }
    @media(max-width:670px) {
        display: inline-block;
    }
}

.add-link {
    display: inline-block;
    float: right;
    margin: 24px 10px;
    font-weight: 500;
    font-size: 14px;
    padding: 4px 8px;
}

.cart-dropdown-alt {
    > a {
        .cart-quantity {
            display: inline-block;
            width: 15px;
            height: 15px;
            font-size: 10px;
            line-height: 15px;
            text-align: center;
            color: #fff;
            background: $color-accent;
            border-radius: 50%;
            position: absolute;
            right: 5px;
            top: 0px;
        }
    }
    .dropdown-menu {
        min-width: 0px;
        margin: 0;
        left: 0;
        position: absolute !important;
        border: none;
        padding: 0;
        margin-top: 25px;
        min-width: 0 !important;
        li {
            margin-bottom: 0;
            > a {
                display: block;
                width: 100%;
                font-size: 12px;
                text-transform: none;
                .row > div {
                    padding: 10px 5px;
                }
            }
            .cart-product-img {
                height: 50px;
            }
            span {
                display: inline-block;
                padding: 17px 5px;
                &.cart-product-quantity {
                    font-weight: 600;
                }
                &.cart-product-name {
                    white-space: nowrap; 
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis; 
                }
            }
            &.subtotal {
                padding: 15px 15px;
                background: $color-accent;
                color: #fff;
                font-size: 12px;
                a, span {
                    font-weight: 600;
                    color: #fff;
                    padding: 0;
                }
                a {
                    text-transform: uppercase;
                    text-decoration: underline;
                    &:hover {
                        background: transparent;
                    }
                }
            }
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }
}

// HEADER
header {
    padding: 80px 0;
    padding-bottom: 0;
    text-align: center;
    .header-title {
        font-weight: 300;
        font-size: 64px;
        line-height: 1.25;
        margin-bottom: 40px;
        b {
            font-weight: 600;
        }
    }
    .header-subtitle {
        font-size: 30px;
        font-weight: 400;
        color: $color-secondary;
    }
}

.main-product-holder {
    position: relative;
    .main-product {
        position: relative;
        width: 100%;
        max-width: 1170px;
        margin: 0 auto;
    }
    img {
        display: block;
        margin: 0 auto;
        height: calc(100vh - 60px);
        max-width: 100vw;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
         -khtml-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
    .scroll-alert-holder {
        position: absolute;
        top: -10px;
        width: 100%;
        opacity: 0;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        padding: 5px 0;
        .scroll-alert-inner {
            animation: scrollAlertAnim 1600ms infinite; 
            animation-fill-mode: forwards;
        }
    }
    &.product-showing {
        .scroll-alert-holder {
            opacity: 1;
        }
    }
    #line-holder {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        line {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            @include transition(stroke-dashoffset .2s ease);
            &.active {
                stroke-dashoffset: 0;
            }
        }
    }
    .info-holder {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        .info {
            position: absolute;
            opacity: 0;
            @include prefix(transform, translateY(20px));
            @include transition(transform .2s ease, opacity .2s);
            &.active {
                opacity: 1;
                @include prefix(transform, translateY(0px));
            }
            .title {
                font-size: 20px;
                font-weight: 700;
                display: inline-block;
                span {
                    position: relative;
                    top: 10px;
                    margin: 0 10px;
                }
            }
            p {
                font-size: 18px;
            }
        }
        .info[data-count="1"] {
            top: 4%;
        }
        .info[data-count="2"] {
            top: 14%;
            right: 0;
            text-align: right;
        }
        .info[data-count="3"] {
            top: 30%;
        }
        .info[data-count="4"] {
            top: 44%;
            right: 0;
            text-align: right;
        }
        .info[data-count="5"] {
            top: 56%;
        }
    }
    .point-holder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        span {
            position: absolute;
            border-radius: 50%;
            background: #eaeaea;
            border: 4px solid $color-accent;
            width: 20px;
            height: 20px;
            box-shadow: 0px 3px 9px rgba(0,0,0,.25);
            opacity: 0;
            @include transition(opacity .3s);
            @include prefix(transform, scale(.3, .3));
            &.active {
                opacity: 1;
                @include prefix(transform, scale(1, 1));
            }
        }
    }
    .scroll-triggers .scroll-trigger {
        position: absolute;
    }
}

section {
    padding: 60px 0;
    .section-title {
        font-size: 48px;
        text-align: center;
        margin: 30px 0;
        font-weight: 600;
    }
}

.how-it-works {
    .how-it-works-col {
        text-align: center;
        padding: 40px 15px;
        .how-it-works-icon {
            height: 100px;
            margin: 30px 0;
            pointer-events: none;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
             -khtml-user-select: none; /* Konqueror HTML */
               -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently
                                          supported by Chrome and Opera */
        }
        .how-it-works-title {
            font-size: 22px;
            font-weight: 700;
        }
        .how-it-works-desc {
            font-size: 18px;
            color: $color-main;
            font-weight: 500;
        }
    }
}

.cta {
    margin-bottom: 50px;
    .cta-box {
        padding: 70px 0;
        position: relative; 
        background-size:cover;
        text-align: center;
        display: inline-block;
        width: 100%;
        color: $color-main;
        text-shadow: 0px 0px 10px rgba(0,0,0,.2);
        @include transition(opacity .2s);
        &:hover {
            opacity: .8;
        }
        .title {
            font-size: 32px;
            font-weight: 700;
            position: relative;
            z-index: 2;
        }
        .cta-add {
            position: absolute;
            z-index: 1;
            pointer-events: none;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
             -khtml-user-select: none; /* Konqueror HTML */
               -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently
                                          supported by Chrome and Opera */
        }
        &.cta-box-1 {
            .cta-add-1 {
                width: 20%;
                bottom: 0%;
                right: 20%;
                @include prefix(transform, translateY(11%));
            }
            .cta-add-2 {
                width: 20%;
                bottom:0;
                right: -5%;
            }
            .cta-add-3 {
                width: 25%;
                top:-25%;
                right: 5%;
            }
        }
        &.cta-box-2 {
            .cta-add-1 {
                width: 45%;
                bottom: 0%;
                right: 0%;
            }
        }
    }
}

.testimonials {
    position: relative;
    padding: 100px 0;
    display: block;
    margin: 0 auto;
    max-width: 700px;
    .owl-testimonials {
        .item {
            text-align: center;
            .quote-title {
                font-size: 36px;
                font-weight: 700;
                margin: 20px 0;
            }
            .quote-text {
                font-size: 20px;
                color: #595959;
                margin: 40px 0;
            }
            .quote-author {
                color: $color-accent;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
    .owl-controls {
        width: 100%;
        a {
            position: absolute;
            background: #fff;
            border-radius: 50%;
            display: inline-block;
            box-shadow: 0px 5px 18px rgba(0,0,0,.1);
            width: 40px;
            height: 40px;
            font-size: 24px;
            color: $color-main;
            line-height: 44px;
            text-align: center;
            top: calc(50% - 20px);
            @include transition(background .2s, box-shadow .2s);
            &:hover {
                background: #f7f7f7;
                box-shadow: 0px 7px 14px rgba(0,0,0,0.15);
            }
            &.owl-prev {
                left: -80px;
            }
            &.owl-next {
                right: -80px;
            }
        }
    }
    .owl-page {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        color: #595959;
        span {
            display: inline-block;
        }
        .divider, .count {
            font-size: 16px;
        }
    }
}

.seo {
    position: relative;
    padding: 100px 0px;
    .container {
        position: relative;
    }
    .owl-seo {
        .item {
            text-align: center;
            padding: 20px 0px;
            .seo-heading {
                background: $color-accent;
                padding: 30px 0;
                color: #fff;
                font-size: 36px;
                font-weight: 700;
                margin-bottom: 40px;
                text-align: center;
            }
            .seo-title {
                text-align: left;
                font-size: 24px;
            }
            .seo-text {
                font-size: 16px;
                text-align: justify;
                width: 100%;
            }
        }
    }
    .owl-controls {
        width: 100%;
        a {
            position: absolute;
            background: #fff;
            border-radius: 50%;
            display: inline-block;
            box-shadow: 0px 5px 18px rgba(0,0,0,.1);
            width: 40px;
            height: 40px;
            font-size: 24px;
            color: $color-main;
            line-height: 44px;
            text-align: center;
            top: calc(50% - 20px);
            @include transition(background .2s, box-shadow .2s);
            &:hover {
                background: #f7f7f7;
                box-shadow: 0px 7px 14px rgba(0,0,0,0.15);
            }
            &.owl-prev {
                left: -40px;
            }
            &.owl-next {
                right: -40px;
            }
        }
    }
    .owl-page {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        span {
            display: inline-block;
        }
        .divider, .count {
            font-size: 16px;
            opacity: .3;
        }
    }
}

.clients {
    margin-bottom: 50px;
    .client {
        text-align: center;
        img {
            max-width: 100%;
            margin: 30px 0;
            pointer-events: none;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
             -khtml-user-select: none; /* Konqueror HTML */
               -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently
                                          supported by Chrome and Opera */
        }
    }
}

.pricing {
    margin: 60px 0;
    .pricing-table {
        text-align: center;
        padding: 40px 40px;
        margin: 20px 0;
        background: #fff;
        border-radius: 3px;
        box-shadow: 0px 10px 30px rgba(0,0,0,.04);
        .pricing-title {
            color: $color-main;
            font-size: 20px;
            font-weight: 700;
        }
        .pricing-description {
            font-size: 16px;
            color: $color-secondary;
        }
        .pricing-price {
            font-weight: 300;
            font-size: 60px;
            margin: 30px 0;
            margin-bottom: 0;
            position: relative;
            display: inline-block;
            .currency {
                position: absolute;
                bottom: 10px;
                right: -20px;
                display: inline-block;
                font-size: 18px;
                margin-left: -10px;
                font-weight: 600;
                color: $color-secondary;
            }
        }
        .pricing-freq {
            margin-top: 0;
            position: relative;
            top: -6px;
            margin-left: 80px;
            color: $color-accent;
            font-weight: 700;
            font-size: 14px;
        }
        .pricing-list {
            padding: 0;
            margin: 40px 0;
            list-style: none;
            text-align: center;
            li {
                margin: 6px 0;
                color: $color-secondary;
                font-size: 14px;
                width: 100%;
            }
        }
        .btn-pricing {
            display: inline-block;
            border-radius: 30px;
            background: $color-accent;
            box-shadow: 0px 5px 16px transparentize($color-accent, .6);
            padding: 14px 70px;
            font-size: 14px;
            color: #fff;
            font-weight: 700;
            text-transform: uppercase;
            margin-top: -5px;
            @include transition(all .2s);
            &:hover {
                opacity: .8;
                box-shadow: 0px 10px 25px transparentize($color-accent, .6);
            }
        }
        .btn-pricing-alt {
            display: inline-block;
            border-radius: 30px;
            background: #fff;
            padding: 12px 60px;
            font-size: 13px;
            color: $color-accent;
            font-weight: 600;
            text-transform: uppercase;
            @include transition(all .2s);
            &:hover {
                background: transparentize(#ebeff2, .6);
                box-shadow: 0px 10px 30px rgba(0,0,0,.04);
            }
        }
        &.pricing-table-accent {
            border-top: 2px solid $color-accent;
            box-shadow: 0px 20px 40px rgba(0,0,0,.1);
            .pricing-title {
                font-size: 32px;
                font-weight: 600;
            }
            .pricing-price {
                font-weight: 500;
                font-size: 64px;
            }
            .pricing-list li {
                font-size: 16px;
                b {
                    color: $color-main;
                    font-weight: 700;
                }
            }
        }
    }
}

.collage {
    .collage-container {
        position: relative;
        padding: 0;
    }
    .collage-item {
        background: gray;
        margin: 15px;
        background-size: cover;
        display: inline-block;
        &.collage-w1 {
            width: calc(25% - 30px);
        }
        &.collage-w2 {
            width: calc(50% - 30px);
        }
        &.collage-h1 {
            height: 292px;
        }
        &.collage-h2 {
            height: 350px;
        }
        .content-holder {
            display: table;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left:0;
            .content {
                display: table-cell;
                vertical-align: middle;
                font-size: 36px;
                font-weight: 700;
                text-align: center;
                color: $color-main;
            }
        }
        &.razor-holder-1 {
            img {
                position: absolute;
                width: 130px;
                left: calc(50% - 65px);
                bottom: 0;
                pointer-events: none;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                 -khtml-user-select: none; /* Konqueror HTML */
                   -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                        user-select: none; /* Non-prefixed version, currently
                                              supported by Chrome and Opera */
            }
        }
        &.razor-holder-2 {
            img {
                position: absolute;
                width: 50px;
                left: calc(50% - 25px);
                top: 0;
                pointer-events: none;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                 -khtml-user-select: none; /* Konqueror HTML */
                   -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                        user-select: none; /* Non-prefixed version, currently
                                              supported by Chrome and Opera */
            }
        }
        &.collage-timer .timer-content {
            font-size: 18px;
            .timer {
                font-weight: 700;
                color: $color-accent;
            }
        }
        &.collage-logo {
            font-size: 18px;
            line-height: 36px;
            img {
                width: 140px;
                margin: 0px 0; 
                pointer-events: none;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                 -khtml-user-select: none; /* Konqueror HTML */
                   -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                        user-select: none; /* Non-prefixed version, currently
                                              supported by Chrome and Opera */
            }
        }
        &.collage-video {
            iframe {
                width: 100%;
                height: 100%;
            }
            .play-button {
                position: absolute;
                left: calc(50% - 30px);
                top: calc(50% - 30px);
                width: 60px;
                height: 60px;
                display: inline-block;
                border-radius: 50%;
                color: #fff;
                background: $color-accent;
                font-size: 28px;
                line-height: 65px;
                text-align: center;
                border: none;
                box-shadow: 0px 10px 25px rgba(0,0,0,.08);
            }
        }
    }
    @media(max-width: 1069px) {
        .collage-item {
            &.collage-w1 {
                width: calc(50% - 30px);
            }
            &.collage-w2 {
                width: calc(100% - 30px);
            }
            &.collage-h1 {
                height: 292px;
            }
            &.collage-h2 {
                height: 350px;
            }
        }
    }
}

footer {
    text-align: center;
    background: #ebeff2;
    padding: 30px 0;
    ul {
        padding: 0;
        margin: 15px 0;
        list-style: none;
        li {
            display: inline-block;
            margin: 0 10px;
            a {
                color: $color-main;
                font-weight: 600;
            }
        }
    }
    .newsletter-form {
        text-align: center;
        margin: 30px 0;
        .input-holder {
            position: relative;
            margin: 0 auto;
            width: 300px;
            overflow: visible;
            input {
                width: 100%;
                border: none;
                font-size: 14px;
                padding: 16px 25px;
                border-radius: 30px;
                padding-right: 100px;
                @include transition(all .2s);
                &:focus {
                    outline: none;
                    box-shadow: 0px 0px 0px 6px transparentize($color-accent, .8);
                }
            }
            button {
                position: absolute;
                top: 12px;
                margin-top: -5px;
                right: 7px;
                padding: 8px 20px;
            }
        }
    }
    .footer-social {
        a {
            display: inline-block;
            width: 40px;
            height: 40px;
            background: $color-main;
            box-shadow: 0px 0px 0 5px transparentize($color-main, .8), 0px 6px 20px transparentize($color-main, .65);
            font-size: 14px;
            line-height: 45px;
            text-align: center;
            border-radius: 50%;
            color: #fff;
            @include transition(transform .2s ease, box-shadow .2s);
            &:hover {
                transform: translateY(-2px);
                box-shadow: 0px 0px 0 5px transparentize($color-main, .8), 0px 10px 25px transparentize($color-main, .5);
            }
        }
	}
	
    .footer-navigation {
        margin: 30px 0;
        a {
            font-size: 18px;
            @include transition(color .15s);
            &:hover {
                color: $color-accent;
            }
        }
    }
    .footer-description {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .footer-copyright {
        font-size: 14px;
        font-weight: 600;
        opacity: .5;
    }
}

.nav-tabs-single > li.active > a {
    border: none !important;
    padding: 10px 20px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 18px;
    background: transparent;
    &:hover, &:focus {
        background: transparent;
    }
}

.tab-content {
    padding: 20px 20px;
}

.terms-checkbox a, .email a {
    color: $color-accent;
}

.btn-confirm-order {
    margin-left: 40px;
}

.contact-title {
    padding-top: 0;
}

a {
    color: $color-accent;
}

.totals span.label {
    color: $color-main;
}

.product-slider .owl-item {
    padding: 10px 10px;
}

.product-slider-controls {
    text-align: center;
    a {
        display: inline-block;
        border-radius: 50%;
        background: #fff;
        width: 40px;
        height: 40px;
        line-height: 45px;
        margin: 0 10px; 
        box-shadow: 0px 5px 15px rgba(0,0,0,.04);
        text-align: center;
        color: $color-accent;
    }
}

.info-holder {
    padding: 15px 0;
}

.nav-try-now {
    background: #53a652;
    color: #fff !important;
    @include transition(opacity .2s !important);
    &:hover {
        color: #fff;
        opacity: .8;
    }
}

.subscription-table {
	width: 100%; }
	.subscription-table > tbody tr > td {
	padding: 8px; 
}

.subscription-order-link {
	font-weight: 500;
}
.btn.btn-cancel-subscription {
	padding: 6px 14px;
	font-size: 12px;
	background: #ee5f5b;
	box-shadow: none;
	position: relative;
	top: -3px; }
	.btn.btn-cancel-subscription:hover, .btn.btn-cancel-subscription:active, .btn.btn-cancel-subscription:focus {
	background: #ee5f5b;
	box-shadow: none; 
}

@import 'incl/responsive';