@media(max-width: 1199px) {
    .main-product-holder {
        .info-holder .info {
            padding: 15px 15px;
        }
        .scroll-alert-holder {
            bottom: 0;
            top: auto;
        }
    }
}

@media(max-width: 1024px) {
    .seo {
        .owl-controls a {
            top: 0px;
            &.owl-prev {
                left: calc(50% - 60px);
            }
            &.owl-next {
                right: calc(50% - 60px);
            }
        }
    }
}

@media(max-width: 991px) {
    header {
        padding: 50px 0;
        .header-title {
            font-size: 48px;
            margin: 0;
        }
    }
    .main-product-holder {
        position: relative;
        padding-top: 0px;
        margin-top: -100px;
        .main-product {
            height: 100vh;
            .info-holder .info {
                position: absolute;
                top: 10px;
                left: 0;
                width: 100%;
                text-align: center;
                padding: 0 40px;
                br {
                    display: none;
                }
                p {
                    font-size: 14px;
                    line-height: 18px;
                }
                &.info-hidden {
                    opacity: 0 !important;
                }
            }
            img {
                height: calc(100vh - 200px);
                position: relative;
                top: 160px;
            }
            #line-holder, .point-holder {
                display: none;
            }
        }
    }
    .cta {
        overflow: hidden;
        .cta-box {
            margin: 60px 0;
        }
        .cta-box-2 {
            .cta-add-1 {
                max-width: 200px;
            }
        }
    }
    .seo {
        padding: 60px 0px;
        .owl-controls {
            a {
                top: -40px;
            }
            a.owl-prev {
                left: calc(50% - 80px);
            }
            a.owl-next {
                right: calc(50% - 80px);
            }
        }
    }
    .testimonials {
        padding: 40px 0px;
        margin-bottom: 80px;
        .owl-controls {
            a {
                top: auto;
                bottom: -40px;
            }
            a.owl-prev {
                left: calc(50% - 60px);
            }
            a.owl-next {
                right: calc(50% - 60px);
            }
        }
    }
    .collage {
        .razor-holder-1, .razor-holder-2 {
            display: none;
        }
    }
}

@media(max-width:670px) {
    header {
        padding: 60px 0;
        .header-title {
            font-size: 32px;
            margin: 0;
        }
    }
    .main-product-holder {
        padding: 0;
    }
    section .section-title {
        font-size: 32px;
        margin: 5px 0;
    }
    .how-it-works {
        .how-it-works-col {
            padding: 10px 10px;
            .how-it-works-icon {
                height: 60px;
                margin: 10px 0;
            }
            .how-it-works-title {
                font-size: 16px;
            }
            .how-it-works-desc {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    .collage {
        padding: 0 5px;
        .collage-item {
            margin: 10px 10px;
            .content-holder .content {
                font-size: 18px;
                line-height: 24px;
            }
            &.collage-w1 {
                width: calc(50% - 20px);
            }
            &.collage-w2 {
                width: calc(100% - 20px);
            }
            &.collage-timer {
                .timer-content {
                    font-size: 14px;
                }
            }
            &.collage-logo {
                img {
                    height: 30px;
                    width: auto;
                }
            }
        }
    }
    nav.nav.nav-top {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 10;
        padding: 0 0;
        &.scrolled {
            background: #fff;
            box-shadow: 0px 10px 20px transparentize($color-secondary, .95);
        }
        &.product-showing {
            @include prefix(transform, translateY(-1000%));
        }
        .logo {
            float: left;
            display: inline-block;
            width: auto;
            padding: 15px 15px;
            text-align: left;
            img {
                height: 45px;
            }
		}
		.account-dropdown a:after {
			display: none; 
		}
        .cart-dropdown {
            > a  .cart-quantity {
                right: auto;
                left: 60px;
            }
            .dropdown-menu ul {
                li > a {
                    padding: 5px 20px;
                    .cart-product-img {
                        height: 40px !important;
                    }
                }
            }
        }
        // .dropdown:not(.dropdown-alt) > .dropdown-menu {
        //     position: relative;
        //     min-width: 0 !important;
        //     top: auto !important;
        //     left: auto !important;
        // }
        .cart-toggle {
            display: inline-block;
            float: right;
            margin: 28px 0px;
            font-size: 14px;
            color: $color-secondary;
            font-weight: 600;
            text-transform: uppercase;
            position: relative;
            .cart-quantity {
                display: inline-block;
                width: 15px;
                height: 15px;
                font-size: 10px;
                line-height: 15px;
                text-align: center;
                color: #fff;
                background: $color-accent;
                border-radius: 50%;
                position: absolute;
                right: -10px;
                top: -5px;
            }
        }
        .navigation-toggle {
            display: inline-block;
            float: right;
            margin: 25px;
            margin-right: 15px;
            margin-left: 10px;
            span {
                display: block;
                width: 20px;
                height: 2px;
                margin: 4px 0;
                background: $color-secondary;
            }
        }
        .navigation > ul.navigation-left {
            display: none;
            position: absolute;
            top: 76px;
            text-align: left;
            background: #fff;
            padding: 0;
            margin: 0;
            width: 100%;
            &:before {
                content: '';
                display: table;
                clear: both;
            }
            > li {
                display: block;
                a {
                    padding: 10px 25px;
                    display: block !important;
                }
                &.dropdown {
                    a:after {
                        right: 20px;
                    }
                }
                .dropdown-menu {
                    float: none;
                    box-shadow: none !important;
                }
            }
        }
        .navigation > ul.navigation-right {
            margin: 0;
            float: right;
            > li {
                padding: 18px 0;
                > a {
                    padding: 10px 10px;
                }
                &.dropdown {
                    > a {
                        &:after {
                            top: 36px;
                        }
                    }
                    .dropdown-menu {
                        box-shadow: none;
                        margin-top: 35px;
                    }
                }
                &.cart-dropdown {
                    > a {
                        position: relative;
                        &:after {
                            display: none;
                        }
                        .cart-quantity {
                            top: 6px;
                            right: auto;
                            left: 25px;
                        }
                    }
                    .dropdown-menu {
						position: absolute;
						min-width: 0;
                        &:after {
                            display: none;
                        }
                        li.subtotal {
                            padding: 10px 0;
                            a {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
        &.nav-show {
            background: #fff;
            .navigation > .navigation-left {
                display: block;
                .dropdown-menu {
                    width: 100%;
                    position: relative !important;
                    left: 0 !important;
                    top: 0 !important;
                }
            }
        }
    }
}