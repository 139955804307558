button, .btn, .button {
	&:active, .active, &:focus, .focus, &:active:hover, &:active:focus {
		outline: none;
		box-shadow: none;
	}
}

a, a:hover, a:focus, a:active {
	text-decoration: none;
}

.form-control {
	box-shadow: none;
	&:focus {
		box-shadow: none;
		outline: none;
	}
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background: transparent;
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-15 {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
.col-sm-15 {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
}