@font-face {
	font-family: 'HK Grotesk';
	src: url('../fonts/HKGrotesk-Medium.eot');
	src: url('../fonts/HKGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HKGrotesk-Medium.woff') format('woff'),
		url('../fonts/HKGrotesk-Medium.ttf') format('truetype'),
		url('../fonts/HKGrotesk-Medium.svg#HKGrotesk-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'HK Grotesk';
	src: url('../fonts/HKGrotesk-Regular.eot');
	src: url('../fonts/HKGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HKGrotesk-Regular.woff') format('woff'),
		url('../fonts/HKGrotesk-Regular.ttf') format('truetype'),
		url('../fonts/HKGrotesk-Regular.svg#HKGrotesk-Regular') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'HK Grotesk';
	src: url('../fonts/HKGrotesk-Light.eot');
	src: url('../fonts/HKGrotesk-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HKGrotesk-Light.woff') format('woff'),
		url('../fonts/HKGrotesk-Light.ttf') format('truetype'),
		url('../fonts/HKGrotesk-Light.svg#HKGrotesk-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'HK Grotesk';
	src: url('../fonts/HKGrotesk-Bold.eot');
	src: url('../fonts/HKGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HKGrotesk-Bold.woff') format('woff'),
		url('../fonts/HKGrotesk-Bold.ttf') format('truetype'),
		url('../fonts/HKGrotesk-Bold.svg#HKGrotesk-Bold') format('svg');
	font-weight: 700;
}

@font-face {
	font-family: 'HK Grotesk';
	src: url('../fonts/HKGrotesk-SemiBold.eot');
	src: url('../fonts/HKGrotesk-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HKGrotesk-SemiBold.woff') format('woff'),
		url('../fonts/HKGrotesk-SemiBold.ttf') format('truetype'),
		url('../fonts/HKGrotesk-SemiBold.svg#HKGrotesk-SemiBold') format('svg');
	font-weight: 600;
}
