.animated{
    -webkit-animation-duration:2s;
    animation-duration:.2s;
    -webkit-animation-fill-mode:both;
    animation-fill-mode:both
}

.dropdown-menu {
    animation: dropdownMenuAnim .2s;
    transform-origin: 50% 0;
}

@-webkit-keyframes dropdownMenuAnim {
    from {opacity: 0; transform: scale(1, .9)}
    to {opacity: 1; transform: scale(1, 1)}
}

/* Standard syntax */
@keyframes dropdownMenuAnim {
    from {opacity: 0; transform: scale(1, .9)}
    to {opacity: 1; transform: scale(1, 1)}
}

@-webkit-keyframes scrollAlertAnim {
    0% {opacity: 0; transform: translateY(-5px)}
    60% {opacity: 1; transform: translateY(0px)}
    89% {opacity: 0;}
    100% {transform: translateY(-5px)} 
}

/* Standard syntax */
@keyframes scrollAlertAnim {
    0% {opacity: 0; transform: translateY(-5px)}
    60% {opacity: 1; transform: translateY(0px)}
    89% {opacity: 0;}
    100% {transform: translateY(-2px); opacity: 0} 
}